/* eslint-disable react/jsx-no-useless-fragment */

/* storybook-check-ignore */
import { Box, Link, Text } from '@opendoor/bricks/core';

export interface FaqItem {
  id: string;
  body: string | JSX.Element;
  title: string | JSX.Element;
}

const faqItems = [
  {
    id: 'what-are-opendoor-exclusives',
    title: 'What are Opendoor-Owned Exclusives?',
    body: (
      <>
        <Text>
          Opendoor-Owned Exclusives are homes owned by Opendoor that are offered exclusively to our
          customers for up to 14 days before we list them to the public. We have heard from our
          customers how hard it can be to purchase a home, and how stressful the process can be, so
          we decided to build something better.
        </Text>
        <Text>
          We give our customers the opportunity to purchase these homes before anyone else and make
          the purchasing process simple and streamlined.
        </Text>
      </>
    ),
  },
  {
    id: 'how-is-buying-an-opendoor-exclusive-different-from-the-typical-buying-experience',
    title:
      'How is buying an Opendoor-Owned Exclusive different from the typical buying experience?',
    body: (
      <>
        <Text>
          With Opendoor-Owned Exclusives, we went back to the drawing board to design a purchasing
          experience that’s simple, certain, streamlined and that puts you in control.
          <br />
          Here are some of the differences —
        </Text>
        <Text>
          <b>1. No bidding wars. The price you see is the price you pay</b>
          <br />
          Each Opendoor-Owned Exclusive has a transparent, no-haggle price. If you like a home, you
          can be under contract in a few clicks and without worrying about losing out to a higher
          bid.
        </Text>
        <Text>
          <b>2. Opendoor-Owned Exclusives feature preferred pricing</b>
          <br />
          We offer you exclusive pricing when you purchase an Opendoor-Owned Exclusives home. The
          Exclusives price is lower than the future public list price.
        </Text>
        <Text>
          <b>3. Choose your own close date and how you want to pay</b>
          <br />
          With Opendoor-Owned Exclusives, there is no advantage to paying with cash or closing
          sooner. We put all buyers on equal footing. You can use any lender and choose any close
          date up to 35 days out from signing the contract.
        </Text>
        <Text>
          <b>4. Appraisal Price Match Guarantee</b>
          <br />
          Buy with peace of mind. When you buy an Opendoor-Owned Exclusive, if the home appraises
          for less than the Exclusives price, we’ll reduce the price by up to $50,000 to match the
          appraised value under our{' '}
          <Link
            analyticsName="cosmos-exclusives-faq-apmg"
            aria-label="Learn more about Appraisal Price Match Guarantee"
            href="/exclusives/faq?apmg=1"
          >
            Appraisal Price Match Guarantee®
          </Link>
          .
        </Text>
        <Text>
          <b>5. Back out anytime and get a full refund of your earnest money deposit</b>
          <br />
          If you change your mind at any point in the process before closing, you can back out with
          zero penalties. We want you to feel great about the purchase of your next home.
        </Text>
        <Text>
          <b>6. Flexible self-tour schedule</b>
          <br />
          Any Opendoor-Owned Exclusive displaying the “Self tour instantly” button is open for
          self-tours 8am-7pm local time any day of the week. When you arrive at the home, you can
          either follow the instructions on the door or call (855) 476-0049 and we’ll give you
          access.
        </Text>
        <Text>
          These are some of the differences—but other things haven’t changed! There are things we
          know you rely on to feel confident in a home purchase, and we’ve kept these consistent
          with a typical real estate transaction.
        </Text>

        <Text>
          <b>1. We use the real estate contract used by most local agents.</b>
          <br />
          This is the same contract typically used for home purchases in state.
        </Text>
        <Text>
          <b>2. You’ll have the ability to order a full inspection of the home.</b>
          <br />
          We encourage you to schedule a full home inspection with a licensed inspector so you can
          feel comfortable with and informed about your purchase. You’re welcome to use an inspector
          of your choice, or you can contact us for a list of local options.
        </Text>
      </>
    ),
  },
  {
    id: 'how-does-the-process-work',
    title: 'How does the process work?',
    body: (
      <>
        <Text>
          We’ve streamlined the process of purchasing an Opendoor-Owned Exclusive into 6 easy steps:
        </Text>
        <Text>
          <b>1. Tour the home</b>
          <br />
          Any Opendoor-Owned Exclusive displaying the “Self tour instantly” button is open for
          self-tours 8am-7pm local time any day of the week. When you arrive at the home, you can
          either follow the instructions on the door or call (855) 476-0049 and we’ll give you
          access.
        </Text>
        <Text>
          <b>2. Set up and sign your contract</b>
          <br />
          Each Opendoor-Owned Exclusive has a no-haggle price, so you don’t have to agonize over
          what to offer or whether you’ll lose out to another buyer paying cash. We take a
          first-come, first-served approach—the first person to sign a contract will be under
          contract to purchase the home, no matter how you choose to finance the purchase. If you’d
          like to move forward with a contract, visit the property page for that Exclusive and click
          Buy. We’ll ask you for some information needed to generate a contract, and then we’ll send
          over a contract via email for you to sign. You’ll have 48 hours to sign the
          contract—during that time, the home will be reserved for you and nobody else will be able
          to purchase it. We use the standard real estate contract most commonly used by real estate
          agents in your state.
        </Text>
        <Text>
          <b>3. Submit your earnest money deposit</b>
          <br />
          After signing the contract, you’ll have 5 calendar days to submit your earnest money
          deposit. The deposit is set at 1% of the purchase price. We’ll send you full details on
          how to submit the earnest money deposit once you sign your contract. There is no
          option/due diligence fee required on Opendoor Exclusives.
          <br />
          With Opendoor-Owned Exclusives, you can back out of the purchase any time until closing
          and for any reason, and receive a full refund of your earnest money deposit.
        </Text>
        <Text>
          <b>4. Submit your pre-approval letter</b>
          <br />
          If you plan to finance the purchase of your Opendoor-Owned Exclusive, you’ll have 5
          calendar days after signing the contract to submit your pre-approval letter. Your
          pre-approval must reflect credit verification.
          <br />
          If you decide to purchase the home with cash, you’ll need to submit proof of funds (e.g.,
          a bank statement) within 5 calendar days of signing the contract.
          <br />
          If your purchase of an Opendoor-Owned Exclusive is contingent on the sale of another home,
          the home you’re planning to sell must already be in contract and any contingency periods
          must end no later than 5 days after the contract for the Exclusives home is signed. We’ll
          send you full details on how to submit these materials once you sign your contract for the
          Opendoor-Owned Exclusives home.
        </Text>
        <Text>
          <b>5. Schedule inspections and finalize details with your lender</b>
          <br />
          We encourage you to schedule a full home inspection so that you can feel comfortable with
          your purchase. We will work with your inspector to give them access to the home—just give
          us their details and desired inspection time, and we'll do the rest.
          <br />
          If you're financing the home purchase, you'll also work with your lender to finalize your
          loan. As part of that process, your lender will typically order an appraisal, which is an
          independent valuation of the home. One of the benefits of buying an Opendoor Exclusive is
          that it comes with an{' '}
          <Link
            analyticsName="cosmos-exclusives-faq-how-does-the-process-work-apmg"
            aria-label="Learn more about Appraisal Price Match Guarantee"
            href="/exclusives/faq?apmg=1"
          >
            Appraisal Price Match Guarantee®
          </Link>
          .
        </Text>
        <Text>
          <b>6. Close and get the keys!</b>
          <br />
          You control your closing timeline—you can choose any close date, up to 35 days after
          signing the contract. You can also reschedule your close date without any penalties within
          that 35-day period if something unexpected comes up. If you love a home but are unable to
          close within a 35-day period, please give us a call to discuss your options.
        </Text>
      </>
    ),
  },
  {
    id: 'how-does-the-process-work-customer-owned',
    title: 'How does the process work?',
    body: (
      <Text as="div">
        <ol>
          <li>
            You can request a tour by calling our team at (512) 706-9938 or by clicking “Request a
            tour” on the property description page on our website. In some cases, you can instantly
            reserve a touring slot. If you don’t have an agent, or if your agent isn’t available,
            one of our touring assistants can let you inside.
          </li>
          <li>Tour the home and decide to make it yours.</li>
          <li>
            Start an offer. You’ll see a range of suggested offer prices on the property detail
            page. Our team is available at (512) 706-9938 to help you submit your offer. The seller
            can choose to accept, deny, or counter your offer. If needed, you and the seller can
            negotiate. We’ll handle all the paperwork, which will take the form of a standard
            contract. You’ll also have access to one of our teammates to help explain each step of
            the process.
          </li>
          <li>
            Buyers typically request an Option/Due Diligence period. During this period, we
            encourage you to hire a licensed home inspector to evaluate the condition of the home.
            If there is anything that makes you uncomfortable, you can negotiate any repairs or
            credits to address those issues with the seller. If you decide to cancel the transaction
            within the Option/Due Diligence Period, you can do so and your earnest money will be
            refunded.
          </li>
          <li>
            You can also request a 21 day financing contingency period, during which you will have
            the opportunity to finalize your financing with a lender of your choice. If for some
            reason you are unable to obtain financing, you can cancel the transaction without
            penalty as long as you do so within 21 days and provide documentation from your lender.
          </li>
          <li>
            To help you feel more secure in your purchase, we offer an{' '}
            <Link
              href="/exclusives/faq?apmg=1"
              analyticsName="cosmos-exclusives-how-does-pricing-work-apmg"
              aria-label="Go to Appraisal Price Match Guarantee"
            >
              Appraisal Price Match Guarantee®
            </Link>
            .
          </li>
        </ol>
        <br />
        Have any questions? Call us at{' '}
        <Link
          href="tel:(512)706-9938"
          fontSize="s0"
          lineHeight="s00"
          aria-label=""
          style={{ fontWeight: 500 }}
          analyticsName="cosmos-exclusive-phone-opendoor-exclusives"
        >
          (512) 706-9938
        </Link>{' '}
        and we’d be happy to answer them.
      </Text>
    ),
  },
  {
    id: 'how-does-pricing-work-can-i-negotiate-the-price',
    title: 'How does pricing work? Can I negotiate the price?',
    body: (
      <Text>
        Opendoor-Owned Exclusives are offered at a transparent, no-haggle price—the price you see is
        the price you pay. To give you confidence in our pricing, we offer an{' '}
        <Link
          href="/exclusives/faq?apmg=1"
          analyticsName="cosmos-exclusives-how-does-pricing-work-apmg"
          aria-label="Go to Appraisal Price Match Guarantee"
        >
          Appraisal Price Match Guarantee®
        </Link>
        .
      </Text>
    ),
  },
  {
    id: 'how-often-are-new-opendoor-exclusives-released',
    title: 'How often are new Opendoor Exclusives released?',
    body: (
      <Text>
        We release new homes daily. You can{' '}
        <Link
          aria-label="Join email list"
          href="/exclusives"
          analyticsName="cosmos-exclusive-faq-sign-up-email-list"
        >
          sign up to our email list
        </Link>{' '}
        to have new Exclusives delivered directly to your inbox.
      </Text>
    ),
  },
  {
    id: 'what-happens-when-i-save-a-home-or-subscribe-to-email-updates',
    title: 'What happens when I save a home or subscribe to email updates?',
    body: (
      <Text>
        By saving or subscribing to email updates for a home, you’ll be notified as soon as it
        becomes available to purchase. You’ll also be able to see a list of these homes under “Your
        homes” when you’re logged in to our website.
      </Text>
    ),
  },
  {
    id: 'what-is-the-appraisal-price-match-guarantee',
    title: 'What is the Appraisal Price Match Guarantee?',
    body: (
      <>
        <Text>
          If you are financing the purchase of an Opendoor-Owned Exclusive and the appraised price
          of the property is lower than the Exclusives price, we will reduce the purchase price to
          the appraised price, up to a maximum purchase price reduction of $50,000, so long as: (a)
          your lender is a duly licensed residential mortgage lender, (b) you provide us with an
          appraisal for the property from your lender that is dated no earlier than the executed
          date of the purchase contract, and (c) your lender used an independent, licensed appraiser
          retained through an appraisal management company to generate the appraisal.
        </Text>
        <Text>
          The Guarantee applies to the original Exclusive price, as shown on our website. If you
          choose to increase the price to finance some or all of your closing costs, the Guarantee
          would apply to the Exclusive price but not to the closing costs added.
        </Text>
      </>
    ),
  },
  {
    id: 'why-can-i-buy-this-home-now-for-less-than-it-will-list-for',
    title: 'Why can I buy this home now for less than it will list for?',
    body: (
      <Text>
        Opendoor owns Opendoor-Owned Exclusives, and we can avoid costs when we sell it before we
        list on the MLS.
      </Text>
    ),
  },
  {
    id: 'why-wouldnt-i-just-wait-for-it-to-list',
    title: 'Why wouldn’t I just wait for it to list?',
    body: (
      <Text>
        Someone else may buy the home before then! Also, when you purchase an Opendoor-Owned
        Exclusive you get access to unique features like preferred pricing, our{' '}
        <Link
          analyticsName="cosmos-exclusives-faq-apmg"
          aria-label="Learn more about Appraisal Price Match Guarantee"
          href="/exclusives/faq?apmg=1"
        >
          Appraisal Price Match Guarantee®
        </Link>
        , and the ability to back out anytime until closing without penalties.
      </Text>
    ),
  },
  {
    id: 'what-if-i-go-into-contract-and-change-my-mind',
    title: 'What if I go into contract and change my mind?',
    body: (
      <>
        <Text>
          When you buy an Opendoor-Owned Exclusive, we want to give you the freedom and flexibility
          to make sure you’re making the best decision for you. If you change your mind at any point
          before closing, you can cancel and receive a full refund of your earnest money deposit.
          Just contact us to let us know, and we’ll take things from there.
        </Text>
      </>
    ),
  },
  {
    id: 'if-im-working-with-an-agent-on-an-opendoor-exclusive-how-are-they-compensated',
    title: 'If I’m working with an agent on an Opendoor-Owned Exclusive, how are they compensated?',
    body: (
      <>
        <Text>
          If you choose to work with an agent, Opendoor will cover any commission you have agreed to
          pay your agent, up to 3% of the purchase price.
        </Text>
      </>
    ),
  },
  {
    id: 'what-if-my-purchase-of-an-opendoor-exclusive-is-contingent-on-selling-my-current-home',
    title:
      'What if my purchase of an Opendoor-Owned Exclusive is contingent on selling my current home?',
    body: (
      <>
        <Text>
          If your purchase of an Opendoor-Owned Exclusives home is contingent on the sale of another
          home, the home you’re planning to sell must already be in contract and any contingency
          periods must end no later than 5 days after the purchase contract for the Exclusives home
          has been signed. We ask you to supply us with documentary proof of this (e.g., a purchase
          agreement with all relevant addendums) within 5 days after the purchase contract for the
          Exclusives home has been signed.
        </Text>
        <Text>
          We’ll send you full details on how to submit these materials once you sign your purchase
          contract.
        </Text>
      </>
    ),
  },
  {
    id: 'what-happens-once-the-exclusive-period-ends',
    title: 'What happens once the Exclusive period ends?',
    body: (
      <Text>
        Once the Exclusive period ends, the home is listed to the public, with a higher list price.
        You’ll still be able to buy it, but it will be publicly listed and exposed to more
        competition.
      </Text>
    ),
  },
  {
    id: 'how-can-i-tour-an-opendoor-exclusive',
    title: 'How can I tour an Opendoor-Owned Exclusive?',
    body: (
      <Text>
        Any Opendoor-Owned Exclusive displaying the “Self tour instantly” button is open for
        self-tours 8am-7pm local time any day of the week. When you arrive at the home, you can
        either follow the instructions on the door or call (855) 476-0049 and we’ll give you access.
      </Text>
    ),
  },
  {
    id: 'do-i-need-to-use-your-partner-lender-lower-to-purchase-an-opendoor-exclusive',
    title:
      'Do I need to use your partner lender Lower.com to purchase an Opendoor-Owned Exclusive?',
    body: (
      <Text as="div">
        You're welcome to finance your purchase with any lender, but let us know if you'd like to
        finance with{' '}
        <Link
          aria-label=""
          color="inherit"
          target="_blank"
          textDecoration="underline"
          href="https://app.lower.com/mortgage/lightspeed/purchase?affinity_partner_name=Opendoor&utm_source=go&utm_content="
          analyticsName={`cosmos-exclusives-feedback-form-lower-link`}
        >
          Lower.com
        </Link>
        {'. '} As an Opendoor user, you may be eligible for a lender incentive when you finance with
        Lower.com.
      </Text>
    ),
  },

  {
    id: 'whats-the-same-about-the-process-of-buying-a-publicly-listed-home-on-exclusives',
    title: 'What’s the same about the process of buying a publicly listed home on Exclusives?',
    body: (
      <Text as="div">
        When you directly purchase a publicly listed home owned by Opendoor via Exclusives, you get
        these same Exclusives benefits:
        <ul>
          <li>
            If you choose to work with an agent and pay our no-haggle price, Opendoor will cover any
            commission you have agreed to pay your agent, up to 3% of the purchase price.
          </li>
          <li>No bidding wars with our transparent, no-haggle price</li>
          <li>
            <Link
              analyticsName="cosmos-exclusives-faq-apmg"
              aria-label="Learn more about Appraisal Price Match Guarantee"
              href="/exclusives/faq?apmg=1"
            >
              Appraisal Price Match Guarantee®
            </Link>
          </li>
          <li>Choose your own close date up to 35 days out from signing contract</li>
          <li>Back out anytime without penalty</li>
          <li>Flexible self-tours</li>
        </ul>
        <Text>
          You’ll have access to our team via phone, email, text and chat 7 days a week from 8am-7pm
          local time. Contact us at{' '}
          <Link
            href="tel:(512)706-9938"
            fontSize="s0"
            lineHeight="s00"
            aria-label=""
            style={{ fontWeight: 500 }}
            analyticsName="cosmos-exclusive-phone-opendoor-exclusives"
          >
            (512) 706-9938
          </Link>
          , via email at{' '}
          <Link
            analyticsName="cosmos-exclusives-faq-work-with-an-agent-email-exclusive"
            aria-label="Send email to Opendoor Exclusives"
            href="mailto:exclusive@opendoor.com"
          >
            exclusive@opendoor.com
          </Link>
          , or via chat from our{' '}
          <Link
            href="/exclusives"
            analyticsName="cosmos-exclusives-faq-exclusives-website"
            aria-label="Navigate to Exclusives website"
          >
            website
          </Link>
          .
        </Text>
      </Text>
    ),
  },
  {
    id: 'whats-different-about-the-process-of-buying-a-publicly-listed-home-on-exclusives',
    title: 'What’s different about the process of buying a publicly listed home on Exclusives?',
    body: (
      <Text as="div">
        <ul>
          <li>
            If you prefer to make an offer at a different price or terms, your agent can also submit
            an offer at{' '}
            <Link
              analyticsName="cosmos-exclusives-make-offer"
              aria-label="Make offer"
              href="/make-offer"
            >
              opendoor.com/make-offer
            </Link>{' '}
            (but you won’t receive the above Exclusives benefits).
          </li>
          <li>
            Since these homes are publicly listed, you don’t get the same early access advantages.
          </li>
          <li>
            There is no 48 hour reservation period, the first buyer to sign the contract wins.
          </li>
        </ul>
      </Text>
    ),
  },

  {
    id: 'do-i-need-to-pay-to-access-opendoor-exclusives',
    title: 'Do I need to pay to access Opendoor Exclusives?',
    body: (
      <Text as="div">
        There is no fee to join Exclusives, and no requirement that you’ve transacted with Opendoor
        in the past. All you need to do is join our email list, which you can do{' '}
        <Link
          aria-label="Join email list"
          href="/exclusives"
          analyticsName="cosmos-exclusive-faq-join-email-list"
        >
          here
        </Link>
        . If you would like to sell your home through Exclusives, there’s no obligation to sell and
        you only pay a commission if your home is successfully sold.
      </Text>
    ),
  },
  {
    id: 'do-i-need-to-work-with-an-agent',
    title: 'Can I work with an agent?',
    body: (
      <>
        <Text>You can choose to buy any Opendoor Exclusive either with or without an agent.</Text>
        <Text>
          If you choose to work with Opendoor directly, you’ll have access to our team via phone,
          email, text and chat 7 days a week from 8am-7pm local time. Contact us at{' '}
          <Link
            href="tel:(512)706-9938"
            fontSize="s0"
            lineHeight="s00"
            aria-label=""
            style={{ fontWeight: 500 }}
            analyticsName="cosmos-exclusive-phone-opendoor-exclusives"
          >
            (512) 706-9938
          </Link>
          , via email at{' '}
          <Link
            analyticsName="cosmos-exclusives-faq-work-with-an-agent-email-exclusive"
            aria-label="Send email to Opendoor Exclusives"
            href="mailto:exclusive@opendoor.com"
          >
            exclusive@opendoor.com
          </Link>
          , or via chat from our{' '}
          <Link
            href="/exclusives"
            analyticsName="cosmos-exclusives-faq-exclusives-website"
            aria-label="Navigate to Exclusives website"
          >
            website
          </Link>
          .
        </Text>
        <Text>
          If you choose to work with an agent, Opendoor will cover any commission you have agreed to
          pay your agent, up to 3% of the purchase price.
        </Text>
      </>
    ),
  },
  {
    id: 'what-do-i-need-to-set-up-a-contract',
    title: 'What do I need to set up a contract?',
    body: (
      <>
        <Text as="div">
          You’ll need the following information to set up a contract:
          <ul>
            <li>Every buyer/co-signer’s full name, email, and phone number </li>

            <li>Financing type</li>
            <li>Down payment amount</li>
            <li>Requested closing date (14-35 days out)</li>
          </ul>
        </Text>

        <Text as="div">
          If working with an agent:
          <ul>
            <li>Agent’s full name, email phone number, license number/MLS ID</li>
            <li>The amount being paid to cover agent commissions</li>
          </ul>
        </Text>
      </>
    ),
  },
  {
    id: 'what-kind-of-contract-will-i-sign',
    title: 'What kind of contract will I sign?',
    body: (
      <Text>
        We use the official real estate contract adopted by the state Real Estate Commission or Bar
        Association. Setting up your contract is easy—just give us some details like your full name
        and when you want to close, and we’ll send over a contract you can sign electronically.
      </Text>
    ),
  },
  {
    id: 'do-i-need-proof-of-financing-to-set-up-a-contract',
    title: 'Do I need proof of financing to set up a contract?',
    body: (
      <>
        <Text>
          If you plan to finance the purchase of the home, you’ll have 5 days after signing the
          contract to submit your pre-approval letter. Your pre-approval must reflect credit
          verification.
        </Text>
        <Text>
          If you decide to purchase the home with cash, you’ll have 5 days to submit proof of funds
          (e.g., a bank statement).
        </Text>
      </>
    ),
  },
  {
    id: 'can-i-use-an-fha-loan-to-finance-my-home-purchase',
    title: 'Can I use an FHA loan to finance my home purchase?',
    body: (
      <Text>
        Some, but not all Opendoor-owned homes can be purchased using an FHA loan due to an FHA rule
        that requires the home to be owned by the seller for more than 90 days. If you need
        assistance choosing a different type of loan to finance your home purchase, please contact
        us for a referral or discuss with your lender.
      </Text>
    ),
  },
  {
    id: 'can-i-use-a-va-loan-to-finance-my-home-purchase',
    title: 'Can I use a VA loan to finance my home purchase?',
    body: (
      <>
        <Text>Yes, you can use a VA loan to buy an Opendoor-Owned Exclusive!</Text>
      </>
    ),
  },
  {
    id: 'should-i-get-the-home-inspected',
    title: 'Should I get the home inspected?',
    body: (
      <>
        <Text>
          We encourage buyers to order an inspection of the home by a licensed home inspector to
          evaluate the condition of the home. You should schedule the inspection early in the
          process (typically within the first few days after signing the contract). You are welcome
          to choose the inspection company of your choice.
        </Text>
        <Text>
          If there is anything in your inspection report that gives you concerns about moving
          forward, please call us at{' '}
          <Link
            href="tel:(512)706-9938"
            fontSize="s0"
            lineHeight="s00"
            aria-label=""
            style={{ fontWeight: 500 }}
            analyticsName="cosmos-exclusive-phone-opendoor-exclusives"
          >
            (512) 706-9938
          </Link>
          .
        </Text>
      </>
    ),
  },
  {
    id: 'what-if-the-home-appraises-for-less',
    // Note: we labeled apmg here so with the header that the APMG question is at the top.
    title: (
      <Box as="span" id="apmg">
        What if the home appraises for less?
      </Box>
    ),
    body: (
      <Text>
        When you buy an Opendoor-Owned Exclusive, if the home appraises for less than Exclusives
        price, we’ll reduce the price by up to $50,000 to match the appraised value under our{' '}
        <Link
          analyticsName="cosmos-exclusives-faq-home-appraises-for-less-apmg"
          aria-label="Learn more about Appraisal Price Match Guarantee"
          href="/exclusives/faq?apmg=1"
        >
          Appraisal Price Match Guarantee®
        </Link>
        .
      </Text>
    ),
  },
  {
    id: 'what-is-a-customer-owned-exclusive',
    title: 'What is an Exclusive owned by a private seller?',
    body: (
      <Text>
        Some homes on our website are not owned by Opendoor. For these homes we collect offers from
        buyers for a limited period of time and present them to the seller.
      </Text>
    ),
  },
  {
    id: 'how-does-the-appraisal-price-match-guarantee-apply-to-customer-owned-exclusives',
    title:
      'How does the Appraisal Price Match Guarantee apply to an Exclusive owned by a private seller?',
    body: (
      <>
        <Text>
          If you are financing the purchase of the property, and the appraised price of the property
          is lower than the purchase price, Opendoor will cover the gap up to $50,000. This benefit
          applies so long as: (a) your lender is a duly licensed residential mortgage lender, (b)
          you provide the seller with an appraisal for the property from your lender that is dated
          no earlier than the executed date of the purchase contract, and (c) your lender used an
          independent, licensed appraiser retained through an appraisal management company to
          generate the appraisal.
        </Text>
      </>
    ),
  },
  {
    id: 'do-i-need-to-work-with-an-agent-3p',
    title: 'Can I work with an agent?',
    body: (
      <>
        <Text>
          You can choose to work with or without an agent. If you choose to work with us directly,
          you’ll have access to our team via phone, email, text and chat 7 days a week from 7am-7pm
          Central time, 8am-8pm Eastern time. Contact us at{' '}
          <Link
            href="tel:(512)706-9938"
            fontSize="s0"
            lineHeight="s00"
            aria-label=""
            style={{ fontWeight: 500 }}
            analyticsName="cosmos-exclusive-phone-opendoor-exclusives"
          >
            (512) 706-9938
          </Link>
          , email us at{' '}
          <Link
            analyticsName="cosmos-exclusives-faq-work-with-an-agent-email-exclusive"
            aria-label="Send email to Opendoor Exclusives"
            href="mailto:exclusive@opendoor.com"
          >
            exclusive@opendoor.com
          </Link>
          , or via chat from our{' '}
          <Link
            href="/exclusives"
            analyticsName="cosmos-exclusives-faq-exclusives-website"
            aria-label="Navigate to Exclusives website"
          >
            website
          </Link>
        </Text>
        <Text>
          If you choose to work with an agent, Opendoor will cover any commission you have agreed to
          pay your agent, up to 3% of the purchase price.
        </Text>
      </>
    ),
  },
  {
    id: 'can-i-choose-my-closing-date-for-customer-owned-exclusives',
    title: 'Can I choose my closing date for an Exclusive owned by a private seller?',
    body: (
      <Text>
        It’s up to you and the seller to determine a closing date that works for both parties. If
        you’re planning to finance the purchase of your home, your lender will typically need at
        least 25-30 days to close.
      </Text>
    ),
  },

  {
    id: 'do-i-need-a-pre-approval-to-submit-an-offer-for-a-customer-owned-exclusive',
    title:
      'Do I need a pre-approval to submit an offer for an Exclusive owned by a private seller?',
    body: (
      <Text>
        If you are planning to finance your purchase, we will ask you to submit a pre-approval
        letter (that reflects credit verification). If you are planning to purchase the home with
        cash, we will ask you to provide proof of funds.
      </Text>
    ),
  },
  {
    id: 'how-does-pricing-work-for-customer-owned-exclusives',
    title: 'How does pricing work for an Exclusive owned by a private seller?',
    body: (
      <Text>
        We provide a range of suggested offer prices on the property detail page, with an associated
        offer strength rating. A “good” or “great” offer is more likely to be accepted by the
        seller, but we will present offers at any point in the range.
      </Text>
    ),
  },
  {
    id: 'am-i-guaranteed-to-get-the-home-if-i-submit-an-offer-for-a-customer-owned-exclusive',
    title:
      'Am I guaranteed to get the home if I submit an offer for an Exclusive owned by a private seller?',
    body: (
      <Text>
        Sellers can accept, reject or counter any offer you submit. The stronger your offer, the
        more likely a seller is to accept it.
      </Text>
    ),
  },
  {
    id: 'why-can-i-find-publicly-listed-homes-on-exclusives',
    title: 'Why can I find publicly listed homes on Exclusives?',
    body: (
      <Text>
        In addition to homes you can’t find anywhere else, Opendoor Exclusives features homes owned
        by Opendoor which are publicly listed, and you can purchase from us directly. While you
        don’t have early access to these homes, there are still a number of great reasons to
        purchase them on Exclusives.
      </Text>
    ),
  },
  {
    id: '',
    title: '',
    body: (
      <Text>
        We use the{' '}
        <Link
          analyticsName="cosmos-exclusives-faq-texas-standard-contract"
          aria-label="Texas standard contract"
          href="https://www.trec.texas.gov/forms/one-four-family-residential-contract-resale-0"
          target="_blank"
          rel="noopener noreferrer"
        >
          real estate contract
        </Link>{' '}
        adopted by the Texas Real Estate Commission. Setting up your contract is easy—just give us
        some details like your full name and when you want to close, and we’ll send over a contract
        you can sign electronically.
      </Text>
    ),
  },
  {
    title: 'How can I tour an Exclusive owned by a private seller?',
    id: 'how-can-i-tour-a-customer-owned-exclusive',
    body: (
      <Text as="div">
        <Text>
          Click “Request a tour” on the property detail page. This will give you the option to
          instantly reserve a spot in any pre-scheduled Open Houses, or to request a new time.
        </Text>
        <Text>
          If you’re requesting a new time, we recommend you select at least three time slots so
          there’s a greater chance of the seller being able to accommodate you. To give us time to
          contact the seller and schedule your showing, we require at least 2-4 hours notice for
          most new tours.
        </Text>
        <Text>
          We’ll send you reminders leading up to the tour. If you don’t have an agent, or they
          aren’t available, we can send a tour assistant to let you into the home.
        </Text>
        <Text>
          If you have any questions or would like to cancel your tour, please contact us at{' '}
          <Link
            href="tel:(512)706-9938"
            fontSize="s0"
            lineHeight="s00"
            aria-label=""
            style={{ fontWeight: 500 }}
            analyticsName="cosmos-exclusive-phone-opendoor-exclusives"
          >
            (512) 706-9938
          </Link>
        </Text>
      </Text>
    ),
  },
] as const;

export type FaqItemId = (typeof faqItems)[number]['id'];

export function getFaqItems(ids: Array<(typeof faqItems)[number]['id']>) {
  return ids.map((id) => faqItems.find((item) => item.id === id)).filter(Boolean) as FaqItem[];
}
