/* storybook-check-ignore */
import React, { PropsWithChildren } from 'react';

import Head from 'next/head';
import { useRouter } from 'next/router';

interface Props {
  title: string;
  description?: string;
  image?: string;
}

const DEFAULT_OPENGRAPH_IMAGE =
  'https://images.opendoor.com/source/s3/imgdrop-production/0de83246f5284584b22165a7cb562cb7.png?preset=square-2048';
const DEFAULT_DESCRIPTION =
  'Opendoor Exclusives has off-market homes you can’t find anywhere else. Skip the bidding wars. Self-tour and buy off-market homes at exclusive prices. We even offer an Appraisal Price Match Guarantee.';

const Seo: React.FC<Props & PropsWithChildren> = ({
  title,
  description = DEFAULT_DESCRIPTION,
  image = DEFAULT_OPENGRAPH_IMAGE,
  children,
}) => {
  const router = useRouter();
  const path = router?.asPath.split('?')[0];
  const pathLink = `https://www.opendoor.com${path}`;

  return (
    <Head>
      <meta key="description" name="description" content={description} />
      <meta key="og:url" property="og:url" content={pathLink} />
      <meta key="og:title" property="og:title" content={title} />
      <meta key="og:description" property="og:description" content={description} />
      <meta key="og:image" property="og:image" content={image} />
      <meta key="twitter:url" property="twitter:url" content={pathLink} />
      <meta key="twitter:title" name="twitter:title" content={title} />
      <meta key="twitter:description" name="twitter:description" content={description} />
      <meta key="twitter:image" name="twitter:image" content={image} />
      <link key="canonical" rel="canonical" href={pathLink} />
      {children}
    </Head>
  );
};

export default Seo;
