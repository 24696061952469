/* storybook-check-ignore */
import React from 'react';

import { css } from '@emotion/react';
import { AccordionListItem, List, Text } from '@opendoor/bricks/core';
import { IconName } from '@opendoor/bricks/core/Icon';
import SelectiveSpritesheet from '@opendoor/bricks/core/Icon/SelectiveSpritesheet';
import ChevronDown from '@opendoor/bricks/core/Icon/SpritesheetIcons/ChevronDown16';

interface Props {
  topBorder?: boolean;
  bottomBorder?: boolean;
  smallBody?: boolean;
  normalItemTitleFont?: boolean;
  noLeftPadding?: boolean;
  boldTitle?: boolean;
}

const styles = {
  list: css`
    background-color: transparent;
    border-bottom: 1px solid var(--colors-neutrals40);
    border-radius: unset;

    > li {
      box-shadow: unset !important;
      border-radius: unset;
      border: unset;
      padding: var(--space-4);
      button {
        p {
          font-size: var(--fontSizes-s1);
          line-height: var(--lineHeights-s1);
          font-weight: var(--fontWeights-semibold);
        }
      }
      > div {
        p {
          padding-bottom: 1rem;
        }
        p:last-child {
          padding-bottom: 0;
        }
      }
      > div[aria-hidden='false'] {
        margin-top: var(--space-5);
      }
      border-top: 1px solid var(--colors-neutrals40);
      &:focus-within {
        border-radius: unset;
        border: unset;
        border-top: 1px solid var(--colors-neutrals40);
      }
    }
  `,
  hideTopBorder: css`
    li:first-of-type {
      border-top: unset;
      &:focus-within {
        border-top: unset;
      }
    }
  `,
  hideBottomBorder: css`
    border-bottom: unset;
  `,
  noLeftPadding: css`
    li {
      padding-left: var(--space-0);
      button {
        padding-left: var(--space-0);
      }
      > div {
        padding-left: var(--space-0);
      }
    }
  `,
  normalItemTitle: css`
    li {
      font-weight: 400;
      font-size: 16px;
      line-height: 120%;
      color: var(--colors-neutrals100);
      opacity: 0.8;
    }
  `,
  boldTitle: css`
    li button p {
      font-weight: var(--fontWeights-bold);
    }
  `,
};

export type AccordionItem = {
  id: string;
  title: React.ReactNode;
  body: React.ReactNode;
  startExpanded?: boolean;
};

/**
 * @deprecated Use `ExclusivesAccordion` instead of this component.
 */
export default function Accordion({
  items,
  accordionName,
  topBorder,
  bottomBorder,
  smallBody,
  normalItemTitleFont,
  noLeftPadding,
  boldTitle,
}: Props & { items: AccordionItem[]; accordionName: string }) {
  return (
    <>
      <SelectiveSpritesheet icons={[ChevronDown as unknown as { name: IconName; svg: string }]} />
      <List
        variant="accordion"
        css={[
          styles.list,
          !topBorder && styles.hideTopBorder,
          !bottomBorder && styles.hideBottomBorder,
          noLeftPadding && styles.noLeftPadding,
          normalItemTitleFont && styles.normalItemTitle,
          boldTitle && styles.boldTitle,
        ]}
      >
        {items.map(({ id, body, title, startExpanded }, i) => (
          <AccordionListItem
            id={id}
            key={`${accordionName}Item${i}`}
            title={
              <Text as="div" fontWeight="semibold" fontSize="s1">
                {title}
              </Text>
            }
            panelId={id}
            paddingInlineEnd="1rem"
            paddingInlineStart="0"
            startExpanded={startExpanded}
            maxHeight="fit-content"
          >
            <Text
              as="div"
              cursor="auto"
              textAlign="left"
              {...(smallBody
                ? { fontSize: 's00', lineHeight: 's000', color: 'neutrals90' }
                : { fontSize: 's1', lineHeight: 's0' })}
            >
              {body}
            </Text>
          </AccordionListItem>
        ))}
      </List>
    </>
  );
}
